export default {
  views: {
    header: {
      menu_1: "中 / 英",
      subMenu_1a: "English",
      subMenu_1b: "简体中文",
    },

    home: {
      contactInfo: {
        title: "聯絡",
        hkAddress: "香港地址：香港上环 德辅道中199号 无限极广场1702室",
        contactPerson_1: "叶毅恒",
        contactEmail_1: "henryyip@jxsjchem.com",
        contactNo_1: "(+852) 28510255",
        cnAddress: "中国（大陆）地址：浙江嘉兴港区平海路西侧（314201）",
        contactPerson_2: "陈娴",
        contactEmail_2: "chenx@jxsjchem.com",
        contactNo_2: "(+86) 57385286836",
      },
      content: {
        p1: "佳都國際有限公司及其附屬公司主要於中國從事生產及供應環氧乙烷（「環氧乙烷」）、乙二醇（「乙二醇」）、丙烯、聚丙烯（「聚丙烯」）、甲基叔丁基醚（「甲基叔丁基醚」）及表面活性劑業務。本集團亦於中國向其客戶提供聚丙烯、甲基叔丁基醚及表面活性劑加工服務，以及生產及供應其他化工產品，包括碳四、未加工戊烯及工業氣體（如氧氣、氮氣及氬氣）。環氧乙烷為生產乙烯衍生產品的主要仲介成分，乙烯衍生產品包括乙醇胺及乙二醇醚，以及不同種類的表面活性劑。乙二醇為一種用於生產其他生物有機化學產品的半製成品，如用於生產滌綸及防凍化學液體的乙二醇。丙烯通常用於生產聚丙烯、丙烯腈、環氧丙烷及丙酮等，以生產各類重要的有機化工原料以及生產合成樹脂、合成橡膠及若干其他精細化工品。聚丙烯是一種熱塑性樹脂，可用於針織產品、注塑產品、電影產品、纖維產品、管道等。甲基叔丁基醚是一種汽油添加劑，用作提高辛烷值的含氧化合物， 幾乎完全用作汽油發動機燃料的燃料成分。表面活性劑在不同行業廣泛用作精練劑、潤濕劑、乳化劑及增溶劑。",
        p2a: "董事認為，本公司的控股公司為中國三江精細化工有限公司",
        p2b: "(",
        p2c: ")。",
      },
    },
  },
};
